* {
    // App grid units
    --g-8: 8px;
    --g-16: 16px;
    --g-24: 24px;
    --g-32: 32px;
    --g-40: 40px;
    --g-48: 48px;
    --g-56: 56px;
    --g-64: 64px;
    --g-72: 72px;
    --g-80: 80px;
    --g-88: 88px;
    --g-96: 96px;

    // Border radius
    --primary-border-radius: 4px;
    --secondary-border-radius: 8px;
    --tertiary-border-radius: 12px;

    // Box shadow
    --primary-box-shadow: 9px 0px 12px 0px rgba(14, 71, 117, 0.08) inset;
    --secondary-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1), 0px 0px 4px 0px rgba(0, 0, 0, 0.1);

    // Grid
    --grid-item-height: 44px;

    // Card
    --card-item-height: 32px;
    --card-title-height: 48px;

    // Header
    --header-height: 56px;

    // Sidebar
    --collapsed-sidebar: 59px;
    --expanded-sidebar: 300px;
    --logo-height: 49px;
    --logo-width: 35px;

    // Summary
    --summary-item-height: 70px;

    // Modal
    --modal-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14);
    --modal-max-width: 480px;

    // Organization Switcher
    --organization-switcher-panel-max-width: 304px;
    --organization-switcher-panel-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14);
    --organization-switcher-panel-header-height: 70px;
    --organization-switcher-main-content-bg: rgba(23, 92, 137, 0.5);
}

* {
    // App main colors
    --primary-color: #175c89;
    --secondary-color: #0095ff;
    --tertiary-color: #85b1ce;
    --quaternary-color: #98b7d2;
    --quinary-color: #558db3;
    --accent-color: #e3ecf2;
    --hover-focus-color: #457da6;
    --active-color: #0e4062;

    // App neutral colors
    --white: #ffffff;
    --black: #000000;
    --lighter-gray: #f5f8fa;
    --light-gray: #707070;
    --light-gray-2: #d1d1d1;
    --light-gray-3: #e0e0e0;
    --light-gray-4: #b3b3b3;
    --light-gray-5: #010101;
    --medium-gray: #5e6162;
    --dark-gray: #323130;
    --darker-gray: #242424;

    // Header
    --header-background-color: #ffffff;
    --separator-line-color: #e3ecf2;

    // Section Header
    --section-header-background-primary: #ffffff;

    // Section Sub Header
    --section-sub-header-title-color: #175c89;
    --section-sub-header-text-color: #558db3;
    --section-sub-header-idle-color: #98b7d2;
    --section-sub-header-selected-color: #0095ff;

    // Calendar
    --arrow-color: #175c89;
    --arrow-color-hover: #0095ff;
    --month-color: #175c89;
    --month-color-hover: #0095ff;
    --day-color: #6b6b6b;
    --number-color: #175c89;
    --number-color-disabled: #6b6b6b;
    --number-color-selected: #175c89;

    // Full view Calendar
    --full-view-calendar-border-color: #e3ecf2;
    --full-view-calendar-day-active-color: #0095ff;
    --full-view-calendar-day-active-bg: #eff6ffd9;

    // Cell user
    --avatar-background-color: #e6e6e6;
    --avatar-color: #323130;
    --name-color: #323130;
    --cell-user-font-color: #175c89;
    --title-color: #242424;
    --subtitle-color: #323130;
    --user-avatar: #558db3;

    // Datepicker
    --datepicker-bg: #ffffff;
    --datepicker-line-color: #e3ecf2;
    --selected-date-color: #0095ff;
    --timeline-color: #175c89;

    // Inputs
    --input-line-color: #e3ecf2;
    --input-line-color-active: #175c89;

    // Select
    --select-line-color: #e3ecf2;

    // Grids
    --grid-header-color: #ffffff;
    --grid-header-border-color: #85b1ce;
    --grid-border-color: #e3ecf2;
    --grid-hover-color: #e3ecf2;
    --grid-header-text: #174482;
    --grid-row-text: #175c89;

    // Cards
    --card-bg-color: #ffffff;
    --card-highlight-bg-color: #f5f8fa;
    --card-header-border-color: #85b1ce;
    --card-border-color: #e3ecf2;
    --card-border-editable-color: #98b7d2;
    --card-header-text: #174482;
    --card-row-text: #175c89;
    --card-idle-text-color: #558db3;
    --card-banner-bg-color: #e3ecf2;

    // Grid order arrows
    --grid-order-arrow: #85b1ce;
    --grid-order-arrow-active: #174482;

    // Pagination
    --pagination-color: #175c89;
    --pagination-color-hover-focus: #0095ff;
    --pagination-current-color: #0095ff;
    --pagination-disabled: #c0c0c0;

    // Sidebar
    --sidebar-background-color: #175c89;
    --sidebar-line-color: #f0f0f0;
    --sidebar-title-color: #ffffff;

    // Summary
    --summary-item-color: #e3ecf2;
    --summary-item-color-selected: #85b1ce;

    // Tabs
    --tab-item-color: #175c89;
    --tab-item-color-active: #0095ff;
    --tab-item-color-disabled: #bdbdbd;
    --tab-item-icon-color: #175c89;
    --tab-item-icon-color-active: #0095ff;
    --tab-item-column-border-color: #85b1ce;

    // Validation
    --success-validation-color: #60c710;
    --pending-validation-color: #ddad24;
    --danger-validation-primary-color: #ff0000;
    --danger-validation-secondary-color: #ff6b00;
    --danger-validation-tertiary-color: #f9efce;

    // Notifications
    --close-button-color: #175c89;
    --close-button-color-focus: #0095ff;

    // Modal
    --modal-content-bg: #ffffff;
    --modal-title-color: #ffffff;
    --modal-header-bg: #175c89;
    --modal-content-color: #175c89;
    --modal-close-color: #ffffff;
    --modal-close-color-hover: #f5f5f5;
    --modal-cancel-button-text: #175c89;

    // Organization Switcher Panel
    --organization-switcher-panel-content-bg: #ffffff;
    --organization-switcher-panel-header-bg: #f5f8fa;
    --organization-switcher-panel-item-hover: #afccdf;
    --organization-switcher-panel-item-selected: #85b1ce;

    // Status Indicator
    --status-indicator-success-color: #e5f4ea;
    --status-indicator-error-color: #f605051a;
    --status-indicator-pending-color: #fff2dc;
    --status-indicator-info-color: #e3ecf2;
    --status-indicator-idle-color: #5e61621a;

    // Charts
    --chart-current-primary-color: #0095ff;
    --chart-current-secondary-color: #0095ff80;
    --chart-previous-primary-color: #175c89;
    --chart-previous-secondary-color: #175c8980;
    --chart-tertiary-color: #85b1ce;

    --danger-action-primary-color: #e06363;
    --danger-action-secondary-color: #da1111;

    // Alerts

    --alert-warning-color: #856404;
    --alert-warning-background-color: #fff3cd;
    --alert-danger-color: #e06363;
    --alert-danger-background-color: #fbe7e7;
    --alert-info-color: #175c89;
    --alert-info-background-color: #e3ecf2;
    --alert-success-color: #155724;
    --alert-success-background-color: #d4edda;
}

@use "variables/colors";
@use "variables/custom-properties";
@use "variables/fonts";
@use "variables/media-queries";
@use "mixins";

//Defaults
$primary-font:
    "Roboto",
    -apple-system,
    BlinkMacSystemFont,
    Tahoma,
    Geneva,
    Verdana,
    sans-serif;

* {
    font-family: var(--primary-font, $primary-font);
    font-size: inherit;

    html {
        overflow-x: hidden;
    }

    body {
        @include mixins.custom_scrollbar();

        ::-moz-selection {
            color: --white;
            background: --primary-color;
        }

        ::selection {
            color: --white;
            background: --primary-color;
        }

        a {
            text-decoration: none;
            transition: 0.3s ease-in-out;
        }

        .main-padding {
            padding: var(--g-32, 32px);
        }

        input {
            height: var(--g-32);
        }

        input[type="radio"] {
            width: var(--g-16, 16px);
            height: var(--g-16, 16px);
            accent-color: var(--primary-color);
        }

        input[type="checkbox"] {
            accent-color: var(--primary-color);
            width: var(--g-16, 16px);
            height: var(--g-16, 16px);
            cursor: pointer;
            transition: 0.3s ease-in-out;

            &:disabled {
                cursor: default;
            }
        }

        input,
        textarea {
            box-sizing: border-box;
            color: var(--primary-color);
            font-family: var(--primary-font, $primary-font);
            font-size: 14px;
            border: 1px solid var(--input-line-color);
            border-radius: var(--primary-border-radius, 4px);
            padding-left: var(--g-8, 8px);
            padding-right: var(--g-8, 8px);

            @media (orientation: landscape) {
                @media #{media-queries.$break-xxxl} {
                    padding-left: 0.4vw;
                    padding-right: 0.4vw;
                }
            }

            @media (orientation: portrait) {
                @media #{media-queries.$break-lg} {
                    padding-left: 0.8vw;
                    padding-right: 0.8vw;
                }
            }

            &::placeholder {
                color: var(--tertiary-color);
                text-decoration: none;
                cursor: text;
            }

            &:focus {
                outline: none;
                border: 1px solid var(--primary-color);
            }

            &:read-only {
                background-color: transparent;
                border: none;
            }
        }

        textarea {
            @include mixins.custom_scrollbar();
        }

        .hide {
            display: none !important;
        }
    }
}

body {
    &::ng-deep {
        .ag-body-horizontal-scroll-viewport, .ag-body-vertical-scroll-viewport {
            @include mixins.custom_scrollbar();
        }
    }

    app-notifications lib-no-data {
        height: calc(100dvh - 56px - 52px - 32px - 16px);
        display: block;

        @media (orientation: landscape) {
            @media #{media-queries.$break-xxxl} {
                height: calc(100dvh - 2.917vw - 2.708vw - 1.66vw - 0.83vw);
            }
        }

        @media (orientation: portrait) {
            @media #{media-queries.$break-lg} {
                height: calc(100dvh - 5.834vw - 5.416vw - 3.2vw - 1.66vw);
            }
        }
    }

    app-forbidden lib-no-data {
        height: calc(100dvh - 56px - 32px - 16px);
        display: block;

        @media (orientation: landscape) {
            @media #{media-queries.$break-xxxl} {
                height: calc(100dvh - 2.917vw - 1.66vw - 0.83vw);
            }
        }

        @media (orientation: portrait) {
            @media #{media-queries.$break-lg} {
                height: calc(100dvh - 5.834vw - 3.2vw - 1.66vw);
            }
        }
    }

    app-reports-executions-edit {
        height: calc(100dvh - 56px);
        overflow: hidden;
        display: block;
    }

    app-reports-executions-filters {
        height: calc(100dvh - 56px - 32px - 84px);
        overflow: auto;
        @include mixins.custom_scrollbar();
        display: block;

        @media (orientation: landscape) {
            @media #{media-queries.$break-xxxl} {
                height: calc(100dvh - 3vw - 1.66vw - 4.4vw);
            }
        }

        @media (orientation: portrait) {
            @media #{media-queries.$break-lg} {
                height: calc(100dvh - 6.5vw - 3.2vw - 8.3vw);
            }
        }
    }

    app-reports-executions-main,
    app-companies-main,
    app-buildings-main,
    app-contacts-main,
    app-opportunities-main,
    app-accounts-main {
        lib-no-data {
            height: 70dvh;
            display: block;

            @media #{media-queries.$break-md} {
                height: 73dvh;
            }

            @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                max-height: 66dvh;
            }

            @media #{media-queries.$break-xxl} {
                height: 73dvh;
            }

            @media (orientation: landscape) {
                @media #{media-queries.$break-xxxl} {
                    height: 80dvh;
                }
            }
        }
    }

    app-opportunities-single {
        app-notes {
            lib-tab-view {
                .tabContainer {
                    max-height: 57dvh;
                    overflow: auto;
                    @include mixins.custom_scrollbar();

                    @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                        max-height: 47dvh;
                    }

                    @media #{media-queries.$break-xxl} {
                        max-height: 57dvh;
                    }

                    @media (orientation: landscape) {
                        @media #{media-queries.$break-xxxl} {
                            max-height: 66dvh;
                        }
                    }
                }
            }
        }

        app-opportunities-single-contacts,
        app-opportunities-single-team,
        app-opportunities-single-activity-log {
            lib-grid-view {
                .container {
                    lib-generic-grid {
                        .grid-wrapper {
                            max-height: 61dvh;

                            @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                                max-height: 54dvh;
                            }

                            @media #{media-queries.$break-xxl} {
                                max-height: 61dvh;
                            }

                            @media (orientation: landscape) {
                                @media #{media-queries.$break-xxxl} {
                                    max-height: 71dvh;
                                }
                            }
                        }
                    }
                }
            }
        }

        app-opportunities-single-estimates {
            max-height: 70dvh;
            overflow: auto;
            display: block;
            @include mixins.custom_scrollbar();

            @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                max-height: 61dvh;
            }

            @media #{media-queries.$break-xxl} {
                max-height: 70dvh;
            }

            @media (orientation: landscape) {
                @media #{media-queries.$break-xxxl} {
                    max-height: 75dvh;
                }
            }
        }

        app-notes,
        app-opportunities-single-activity-log {
            lib-no-data {
                height: 50dvh;
                display: block;

                @media #{media-queries.$break-md} {
                    height: 60dvh;
                }

                @media (orientation: landscape) {
                    @media #{media-queries.$break-xxxl} {
                        height: 70dvh;
                    }
                }
            }
        }

        app-opportunities-single-contacts,
        app-opportunities-grid-single,
        app-opportunities-single-buildings,
        app-opportunities-single-team,
        app-opportunities-single-estimates {
            lib-no-data {
                height: 45dvh;
                display: block;

                @media #{media-queries.$break-md} {
                    height: 52dvh;
                }

                @media (orientation: landscape) {
                    @media #{media-queries.$break-xxxl} {
                        height: 68dvh;
                    }
                }
            }
        }
    }

    app-contacts-single {
        app-notes {
            lib-tab-view {
                .tabContainer {
                    max-height: 57dvh;
                    overflow: auto;
                    @include mixins.custom_scrollbar();

                    @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                        max-height: 47dvh;
                    }

                    @media #{media-queries.$break-xxl} {
                        max-height: 57dvh;
                    }

                    @media (orientation: landscape) {
                        @media #{media-queries.$break-xxxl} {
                            max-height: 66dvh;
                        }
                    }
                }
            }
        }

        app-opportunities-grid-single {
            lib-grid-view {
                .container {
                    lib-generic-grid {
                        .grid-wrapper {
                            max-height: 70dvh;

                            @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                                max-height: 62dvh;
                            }

                            @media #{media-queries.$break-xxl} {
                                max-height: 70dvh;
                            }

                            @media (orientation: landscape) {
                                @media #{media-queries.$break-xxxl} {
                                    max-height: 75dvh;
                                }
                            }
                        }
                    }
                }
            }
        }

        app-contacts-single-buildings {
            lib-grid-view {
                .container {
                    lib-generic-grid {
                        .grid-wrapper {
                            max-height: 70dvh;

                            @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                                max-height: 62dvh;
                            }

                            @media #{media-queries.$break-xxl} {
                                max-height: 70dvh;
                            }

                            @media (orientation: landscape) {
                                @media #{media-queries.$break-xxxl} {
                                    max-height: 75dvh;
                                }
                            }
                        }
                    }
                }
            }
        }

        app-notes {
            lib-no-data {
                height: 45dvh;
                display: block;

                @media #{media-queries.$break-md} {
                    height: 53dvh;
                }

                @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                    height: 47dvh;
                }

                @media #{media-queries.$break-xxl} {
                    height: 53dvh;
                }

                @media (orientation: landscape) {
                    @media #{media-queries.$break-xxxl} {
                        height: 67dvh;
                    }
                }
            }
        }

        app-buildings-single-contacts,
        app-opportunities-grid-single,
        app-contacts-single-buildings {
            lib-no-data {
                height: 55dvh;
                display: block;

                @media #{media-queries.$break-md} {
                    height: 66dvh;
                }

                @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                    height: 62dvh;
                }

                @media #{media-queries.$break-xxl} {
                    height: 66dvh;
                }

                @media (orientation: landscape) {
                    @media #{media-queries.$break-xxxl} {
                        height: 75dvh;
                    }
                }
            }
        }
    }

    app-buildings-single {
        app-notes {
            lib-tab-view {
                .tabContainer {
                    max-height: 57dvh;
                    overflow: auto;
                    @include mixins.custom_scrollbar();

                    @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                        max-height: 47dvh;
                    }

                    @media #{media-queries.$break-xxl} {
                        max-height: 57dvh;
                    }

                    @media (orientation: landscape) {
                        @media #{media-queries.$break-xxxl} {
                            max-height: 66dvh;
                        }
                    }
                }
            }
        }

        app-opportunities-grid-single {
            lib-grid-view {
                .container {
                    lib-generic-grid {
                        .grid-wrapper {
                            max-height: 70dvh;

                            @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                                max-height: 62dvh;
                            }

                            @media #{media-queries.$break-xxl} {
                                max-height: 70dvh;
                            }

                            @media (orientation: landscape) {
                                @media #{media-queries.$break-xxxl} {
                                    max-height: 75dvh;
                                }
                            }
                        }
                    }
                }
            }
        }

        app-buildings-single-contacts {
            lib-grid-view {
                .container {
                    lib-generic-grid {
                        .grid-wrapper {
                            max-height: 65dvh;

                            @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                                max-height: 55dvh;
                            }

                            @media #{media-queries.$break-xxl} {
                                max-height: 65dvh;
                            }

                            @media (orientation: landscape) {
                                @media #{media-queries.$break-xxxl} {
                                    max-height: 71vh;
                                }
                            }
                        }
                    }
                }
            }
        }

        app-notes {
            lib-no-data {
                height: 45dvh;
                display: block;

                @media #{media-queries.$break-md} {
                    height: 53dvh;
                }

                @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                    height: 47dvh;
                }

                @media #{media-queries.$break-xxl} {
                    height: 53dvh;
                }

                @media (orientation: landscape) {
                    @media #{media-queries.$break-xxxl} {
                        height: 67dvh;
                    }
                }
            }
        }

        app-buildings-single-contacts,
        app-buildings-single-companies,
        app-opportunities-grid-single {
            lib-no-data {
                height: 55dvh;
                display: block;

                @media #{media-queries.$break-md} {
                    height: 66dvh;
                }

                @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                    height: 55dvh;
                }

                @media #{media-queries.$break-xxl} {
                    height: 66dvh;
                }

                @media (orientation: landscape) {
                    @media #{media-queries.$break-xxxl} {
                        height: 75dvh;
                    }
                }
            }
        }
    }

    app-accounts-single {
        app-notes {
            lib-tab-view {
                .tabContainer {
                    height: 57dvh;
                    overflow: auto;
                    @include mixins.custom_scrollbar();

                    @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                        height: 47dvh;
                    }

                    @media #{media-queries.$break-xxl} {
                        height: 57dvh;
                    }

                    @media (orientation: landscape) {
                        @media #{media-queries.$break-xxxl} {
                            height: 66dvh;
                        }
                    }
                }
            }
        }

        app-opportunities-grid-single {
            lib-grid-view {
                .container {
                    lib-generic-grid {
                        .grid-wrapper {
                            max-height: 70dvh;

                            @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                                max-height: 62dvh;
                            }

                            @media #{media-queries.$break-xxl} {
                                max-height: 70dvh;
                            }

                            @media (orientation: landscape) {
                                @media #{media-queries.$break-xxxl} {
                                    max-height: 75dvh;
                                }
                            }
                        }
                    }
                }
            }
        }

        app-accounts-single-buildings,
        app-accounts-single-contacts,
        app-accounts-single-companies {
            lib-grid-view {
                .container {
                    lib-generic-grid {
                        .grid-wrapper {
                            max-height: 65dvh;

                            @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                                max-height: 55dvh;
                            }

                            @media #{media-queries.$break-xxl} {
                                max-height: 65dvh;
                            }

                            @media (orientation: landscape) {
                                @media #{media-queries.$break-xxxl} {
                                    max-height: 71dvh;
                                }
                            }
                        }
                    }
                }
            }
        }

        app-notes {
            lib-no-data {
                height: 45dvh;
                display: block;

                @media #{media-queries.$break-md} {
                    height: 53dvh;
                }

                @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                    height: 47dvh;
                }

                @media #{media-queries.$break-xxl} {
                    height: 53dvh;
                }

                @media (orientation: landscape) {
                    @media #{media-queries.$break-xxxl} {
                        height: 67dvh;
                    }
                }
            }
        }

        app-accounts-single-buildings,
        app-opportunities-grid-single,
        app-accounts-single-contacts,
        app-accounts-single-companies {
            lib-no-data {
                height: 55dvh;
                display: block;

                @media #{media-queries.$break-md} {
                    height: 66dvh;
                }

                @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                    max-height: 55dvh;
                }

                @media #{media-queries.$break-xxl} {
                    max-height: 66dvh;
                }

                @media (orientation: landscape) {
                    @media #{media-queries.$break-xxxl} {
                        height: 75dvh;
                    }
                }
            }
        }
    }

    app-companies-single {
        app-notes {
            lib-tab-view {
                .tabContainer {
                    height: 57dvh;
                    overflow: auto;
                    @include mixins.custom_scrollbar();

                    @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                        height: 47dvh;
                    }

                    @media #{media-queries.$break-xxl} {
                        height: 57dvh;
                    }

                    @media (orientation: landscape) {
                        @media #{media-queries.$break-xxxl} {
                            height: 66dvh;
                        }
                    }
                }
            }
        }

        app-opportunities-grid-single {
            lib-grid-view {
                .container {
                    lib-generic-grid {
                        .grid-wrapper {
                            max-height: 70dvh;

                            @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                                max-height: 62dvh;
                            }

                            @media #{media-queries.$break-xxl} {
                                max-height: 70dvh;
                            }

                            @media (orientation: landscape) {
                                @media #{media-queries.$break-xxxl} {
                                    max-height: 75dvh;
                                }
                            }
                        }
                    }
                }
            }
        }

        app-companies-single-buildings,
        app-companies-single-contacts {
            lib-grid-view {
                .container {
                    lib-generic-grid {
                        .grid-wrapper {
                            max-height: 65dvh;

                            @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                                max-height: 55dvh;
                            }

                            @media #{media-queries.$break-xxl} {
                                max-height: 65dvh;
                            }

                            @media (orientation: landscape) {
                                @media #{media-queries.$break-xxxl} {
                                    max-height: 71dvh;
                                }
                            }
                        }
                    }
                }
            }
        }

        app-notes {
            lib-no-data {
                height: 45dvh;
                display: block;

                @media #{media-queries.$break-md} {
                    height: 53dvh;
                }

                @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                    height: 47dvh;
                }

                @media #{media-queries.$break-xxl} {
                    height: 53dvh;
                }

                @media (orientation: landscape) {
                    @media #{media-queries.$break-xxxl} {
                        height: 67dvh;
                    }
                }
            }
        }

        app-companies-single-buildings,
        app-opportunities-grid-single,
        app-companies-single-contacts {
            lib-no-data {
                height: 55dvh;
                display: block;

                @media #{media-queries.$break-md} {
                    height: 66dvh;
                }

                @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                    max-height: 55dvh;
                }

                @media #{media-queries.$break-xxl} {
                    max-height: 66dvh;
                }

                @media (orientation: landscape) {
                    @media #{media-queries.$break-xxxl} {
                        height: 75dvh;
                    }
                }
            }
        }
    }

    app-configuration {
        app-customization {
            app-customization-custom-fields {
                lib-no-data {
                    height: 55dvh;
                    display: block;

                    @media #{media-queries.$break-md} {
                        height: 63dvh;
                    }

                    @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                        height: 55dvh;
                    }

                    @media #{media-queries.$break-xxl} {
                        height: 63dvh;
                    }

                    @media (orientation: landscape) {
                        @media #{media-queries.$break-xxxl} {
                            height: 75dvh;
                        }
                    }
                }
            }
        }
    }

    app-configuration {
        app-customization {
            app-customization-custom-fields,
            app-customization-custom-info {
                lib-no-data {
                    height: 55dvh;
                    display: block;

                    @media #{media-queries.$break-md} {
                        height: 63dvh;
                    }

                    @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                        height: 55dvh;
                    }

                    @media #{media-queries.$break-xxl} {
                        height: 63dvh;
                    }

                    @media (orientation: landscape) {
                        @media #{media-queries.$break-xxxl} {
                            height: 75dvh;
                        }
                    }
                }
            }
        }
    }

    app-reports-main {
        app-reports-list {
            lib-no-data {
                height: 70dvh;
                display: block;

                @media #{media-queries.$break-md} {
                    height: 70dvh;
                }

                @media #{media-queries.$break-xl} and #{media-queries.$max-xxl} {
                    height: 63dvh;
                }

                @media #{media-queries.$break-xxl} {
                    height: 70dvh;
                }

                @media (orientation: landscape) {
                    @media #{media-queries.$break-xxxl} {
                        height: 80dvh;
                    }
                }
            }
        }
    }
}

/*app-root {
    > div {
        overflow: hidden;
    }
}*/

@import "~mapbox-gl/dist/mapbox-gl.css";
@import "@angular/cdk/overlay-prebuilt.css";
* {
  --primary-font: "Roboto", -apple-system, BlinkMacSystemFont, Tahoma, Geneva, Verdana, sans-serif;
}

* {
  --primary-color: #175c89;
  --secondary-color: #0095ff;
  --tertiary-color: #85b1ce;
  --quaternary-color: #98b7d2;
  --quinary-color: #558db3;
  --accent-color: #e3ecf2;
  --hover-focus-color: #457da6;
  --active-color: #0e4062;
  --white: #ffffff;
  --black: #000000;
  --lighter-gray: #f5f8fa;
  --light-gray: #707070;
  --light-gray-2: #d1d1d1;
  --light-gray-3: #e0e0e0;
  --light-gray-4: #b3b3b3;
  --light-gray-5: #010101;
  --medium-gray: #5e6162;
  --dark-gray: #323130;
  --darker-gray: #242424;
  --header-background-color: #ffffff;
  --separator-line-color: #e3ecf2;
  --section-header-background-primary: #ffffff;
  --section-sub-header-title-color: #175c89;
  --section-sub-header-text-color: #558db3;
  --section-sub-header-idle-color: #98b7d2;
  --section-sub-header-selected-color: #0095ff;
  --arrow-color: #175c89;
  --arrow-color-hover: #0095ff;
  --month-color: #175c89;
  --month-color-hover: #0095ff;
  --day-color: #6b6b6b;
  --number-color: #175c89;
  --number-color-disabled: #6b6b6b;
  --number-color-selected: #175c89;
  --full-view-calendar-border-color: #e3ecf2;
  --full-view-calendar-day-active-color: #0095ff;
  --full-view-calendar-day-active-bg: #eff6ffd9;
  --avatar-background-color: #e6e6e6;
  --avatar-color: #323130;
  --name-color: #323130;
  --cell-user-font-color: #175c89;
  --title-color: #242424;
  --subtitle-color: #323130;
  --user-avatar: #558db3;
  --datepicker-bg: #ffffff;
  --datepicker-line-color: #e3ecf2;
  --selected-date-color: #0095ff;
  --timeline-color: #175c89;
  --input-line-color: #e3ecf2;
  --input-line-color-active: #175c89;
  --select-line-color: #e3ecf2;
  --grid-header-color: #ffffff;
  --grid-header-border-color: #85b1ce;
  --grid-border-color: #e3ecf2;
  --grid-hover-color: #e3ecf2;
  --grid-header-text: #174482;
  --grid-row-text: #175c89;
  --card-bg-color: #ffffff;
  --card-highlight-bg-color: #f5f8fa;
  --card-header-border-color: #85b1ce;
  --card-border-color: #e3ecf2;
  --card-border-editable-color: #98b7d2;
  --card-header-text: #174482;
  --card-row-text: #175c89;
  --card-idle-text-color: #558db3;
  --card-banner-bg-color: #e3ecf2;
  --grid-order-arrow: #85b1ce;
  --grid-order-arrow-active: #174482;
  --pagination-color: #175c89;
  --pagination-color-hover-focus: #0095ff;
  --pagination-current-color: #0095ff;
  --pagination-disabled: #c0c0c0;
  --sidebar-background-color: #175c89;
  --sidebar-line-color: #f0f0f0;
  --sidebar-title-color: #ffffff;
  --summary-item-color: #e3ecf2;
  --summary-item-color-selected: #85b1ce;
  --tab-item-color: #175c89;
  --tab-item-color-active: #0095ff;
  --tab-item-color-disabled: #bdbdbd;
  --tab-item-icon-color: #175c89;
  --tab-item-icon-color-active: #0095ff;
  --tab-item-column-border-color: #85b1ce;
  --success-validation-color: #60c710;
  --pending-validation-color: #ddad24;
  --danger-validation-primary-color: #ff0000;
  --danger-validation-secondary-color: #ff6b00;
  --danger-validation-tertiary-color: #f9efce;
  --close-button-color: #175c89;
  --close-button-color-focus: #0095ff;
  --modal-content-bg: #ffffff;
  --modal-title-color: #ffffff;
  --modal-header-bg: #175c89;
  --modal-content-color: #175c89;
  --modal-close-color: #ffffff;
  --modal-close-color-hover: #f5f5f5;
  --modal-cancel-button-text: #175c89;
  --organization-switcher-panel-content-bg: #ffffff;
  --organization-switcher-panel-header-bg: #f5f8fa;
  --organization-switcher-panel-item-hover: #afccdf;
  --organization-switcher-panel-item-selected: #85b1ce;
  --status-indicator-success-color: #e5f4ea;
  --status-indicator-error-color: #f605051a;
  --status-indicator-pending-color: #fff2dc;
  --status-indicator-info-color: #e3ecf2;
  --status-indicator-idle-color: #5e61621a;
  --chart-current-primary-color: #0095ff;
  --chart-current-secondary-color: #0095ff80;
  --chart-previous-primary-color: #175c89;
  --chart-previous-secondary-color: #175c8980;
  --chart-tertiary-color: #85b1ce;
  --danger-action-primary-color: #e06363;
  --danger-action-secondary-color: #da1111;
  --alert-warning-color: #856404;
  --alert-warning-background-color: #fff3cd;
  --alert-danger-color: #e06363;
  --alert-danger-background-color: #fbe7e7;
  --alert-info-color: #175c89;
  --alert-info-background-color: #e3ecf2;
  --alert-success-color: #155724;
  --alert-success-background-color: #d4edda;
}

* {
  --g-8: 8px;
  --g-16: 16px;
  --g-24: 24px;
  --g-32: 32px;
  --g-40: 40px;
  --g-48: 48px;
  --g-56: 56px;
  --g-64: 64px;
  --g-72: 72px;
  --g-80: 80px;
  --g-88: 88px;
  --g-96: 96px;
  --primary-border-radius: 4px;
  --secondary-border-radius: 8px;
  --tertiary-border-radius: 12px;
  --primary-box-shadow: 9px 0px 12px 0px rgba(14, 71, 117, 0.08) inset;
  --secondary-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1), 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  --grid-item-height: 44px;
  --card-item-height: 32px;
  --card-title-height: 48px;
  --header-height: 56px;
  --collapsed-sidebar: 59px;
  --expanded-sidebar: 300px;
  --logo-height: 49px;
  --logo-width: 35px;
  --summary-item-height: 70px;
  --modal-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14);
  --modal-max-width: 480px;
  --organization-switcher-panel-max-width: 304px;
  --organization-switcher-panel-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14);
  --organization-switcher-panel-header-height: 70px;
  --organization-switcher-main-content-bg: rgba(23, 92, 137, 0.5);
}

button {
  font-family: var(--primary-font, "Roboto");
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--g-8, 8px);
  padding-top: var(--g-8, 8px);
  padding-bottom: var(--g-8, 8px);
  padding-left: var(--g-16, 16px);
  padding-right: var(--g-16, 16px);
  height: var(--g-32, 32px);
  width: 100%;
  line-height: 100%;
  border-radius: var(--primary-border-radius, 4px);
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
}
@media (orientation: landscape) and (min-width: 1920px) {
  button {
    font-size: 0.72vw;
    gap: 0.4vw;
    padding-top: 0.4vw;
    padding-bottom: 0.4vw;
    padding-left: 0.83vw;
    padding-right: 0.83vw;
    height: 1.667vw;
    border-radius: 0.2vw;
  }
}
@media (orientation: portrait) and (min-width: 1081px) {
  button {
    font-size: 1.44vw;
    gap: 0.8vw;
    padding-top: 0.8vw;
    padding-bottom: 0.8vw;
    padding-left: 1.667vw;
    padding-right: 1.667vw;
    height: 3.33vw;
    border-radius: 0.4vw;
  }
}
button.resetStyles {
  font: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  width: auto;
  line-height: normal;
}
@media (orientation: landscape) and (min-width: 1920px) {
  button.resetStyles {
    margin: 0;
    padding: 0;
  }
}
@media (orientation: portrait) and (min-width: 1081px) {
  button.resetStyles {
    margin: 0;
    padding: 0;
  }
}
button.primary-btn {
  font-size: 14px;
  background-color: var(--primary-color, #175c89);
  color: var(--white, #ffffff);
  border: 1px solid var(--primary-color, #175c89);
}
button.primary-btn:hover {
  background-color: var(--hover-color, #457DA6);
  color: var(--white, #ffffff);
  outline: none;
  border-color: var(--hover-color, #457DA6);
}
button.primary-btn:active {
  background-color: var(--active-color, #0E4062);
  color: var(--white, #ffffff);
  outline: none;
  border-color: var(--active-color, #0E4062);
}
button.primary-btn:focus, button.primary-btn:focus-within {
  background-color: var(--hover-color, #457DA6);
  color: var(--white, #ffffff);
  outline: none;
  border-color: var(--hover-color, #457DA6);
}
button.primary-btn:focus:hover, button.primary-btn:focus-within:hover {
  background-color: var(--hover-color, #457DA6);
  color: var(--white, #ffffff);
  outline: none;
  border-color: var(--hover-color, #457DA6);
}
button.primary-btn:focus-visible {
  outline: 1.5px solid var(--black, #000000);
  background-color: var(--primary-color, #175c89);
  color: var(--white, #ffffff);
  border-color: var(--white, #ffffff);
}
button.primary-btn:focus-visible:hover {
  background-color: var(--hover-color, #457DA6);
  color: var(--white, #ffffff);
  outline: 1.5px solid var(--black, #000000);
  border-color: var(--white, #ffffff);
}
button.primary-btn:disabled {
  background-color: #D1D6D9;
  color: #FFFFFF;
  border-color: #D1D6D9;
}
button.primary-btn:disabled svg path {
  stroke: #FFFFFF;
}
@media (orientation: landscape) and (min-width: 1920px) {
  button.primary-btn {
    font-size: 0.72vw;
  }
}
@media (orientation: portrait) and (min-width: 1081px) {
  button.primary-btn {
    font-size: 1.44vw;
  }
}
button.secondary-btn {
  font-size: 14px;
  background-color: transparent;
  color: var(--primary-color, #175c89);
  border: 1px solid var(--primary-color, #175c89);
}
button.secondary-btn:hover {
  background-color: var(--lighter-gray, #F5F8FA);
  border-color: var(--primary-color, #175c89);
}
button.secondary-btn:active {
  background-color: var(--lighter-gray, #E3ECF2);
  border-color: var(--primary-color, #175c89);
}
button.secondary-btn:focus, button.secondary-btn:focus-within {
  outline: none;
  background-color: var(--lighter-gray, #E3ECF2);
  border-color: var(--primary-color, #175c89);
}
button.secondary-btn:focus-visible {
  outline: 1.5px solid var(--black, #000000);
  border-color: var(--primary-color, #175c89);
}
button.secondary-btn:disabled {
  background-color: #FFFFFF;
  color: #98B7D2;
  border-color: #98B7D2;
}
button.secondary-btn:disabled svg path {
  stroke: #98B7D2;
}
@media (orientation: landscape) and (min-width: 1920px) {
  button.secondary-btn {
    font-size: 0.72vw;
  }
}
@media (orientation: portrait) and (min-width: 1081px) {
  button.secondary-btn {
    font-size: 1.44vw;
  }
}
button.tertiary-btn {
  font-size: 14px;
  background-color: transparent;
  border: none;
  color: var(--primary-color, #175c89);
  outline: none;
}
@media (orientation: landscape) and (min-width: 1920px) {
  button.tertiary-btn .icon svg {
    width: 0.625vw;
    height: 0.625vw;
  }
}
@media (orientation: portrait) and (min-width: 1081px) {
  button.tertiary-btn .icon svg {
    width: 1.2vw;
    height: 1.2vw;
  }
}
button.tertiary-btn:active {
  color: var(--primary-color, #0E4062);
  outline: none;
  border: none;
}
button.tertiary-btn:active svg path {
  stroke: var(--primary-color, #0E4062);
}
button.tertiary-btn:hover {
  color: var(--secondary-color, #0095ff);
  outline: none;
  border: none;
}
button.tertiary-btn:hover svg path {
  stroke: var(--secondary-color, #0095ff);
}
button.tertiary-btn:focus, button.tertiary-btn:focus-within {
  color: var(--primary-color, #0E4062);
  outline: none;
  border: none;
}
button.tertiary-btn:focus svg path, button.tertiary-btn:focus-within svg path {
  stroke: var(--primary-color, #0E4062);
}
button.tertiary-btn:focus-visible {
  color: var(--primary-color, #175c89);
  outline: 1.5px solid var(--black, #000000);
  border-color: none;
}
button.tertiary-btn:disabled {
  color: var(--quaternary-color, #98B7D2);
  outline: none;
  border: none;
}
button.tertiary-btn:disabled svg path {
  stroke: var(--quaternary-color, #98B7D2);
}
@media (orientation: landscape) and (min-width: 1920px) {
  button.tertiary-btn {
    font-size: 0.72vw;
  }
}
@media (orientation: portrait) and (min-width: 1081px) {
  button.tertiary-btn {
    font-size: 1.44vw;
  }
}
button.small {
  font-size: 12px;
  height: var(--g-24, 24px);
}
@media (orientation: landscape) and (min-width: 1920px) {
  button.small {
    font-size: 0.62vw;
    height: 1.25vw;
  }
}
@media (orientation: portrait) and (min-width: 1081px) {
  button.small {
    font-size: 1.24vw;
    height: 2.5vw;
  }
}
button.borderless {
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow-x: hidden;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0.5;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

blockquote {
  margin: 0;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

::-ms-reveal {
  display: none;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: url("../../assets/fonts/Roboto-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("../../assets/fonts/Roboto-Light.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: url("../../assets/fonts/Roboto-Black.ttf") format("truetype");
  font-display: swap;
}
* {
  font-family: var(--primary-font, "Roboto", -apple-system, BlinkMacSystemFont, Tahoma, Geneva, Verdana, sans-serif);
  font-size: inherit;
}
* html {
  overflow-x: hidden;
}
* body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  transition: 0.3s ease-in-out;
}
* body::-webkit-scrollbar-track {
  background: #f5f8fa;
  border-radius: 8px;
}
* body::-webkit-scrollbar-thumb {
  background: #175c89;
  border-radius: 8px;
  border: 4px solid rgba(0, 0, 0, 0);
}
* body::-webkit-scrollbar-thumb:hover {
  background: #0095ff;
}
* body ::-moz-selection {
  color: --white;
  background: --primary-color;
}
* body ::selection {
  color: --white;
  background: --primary-color;
}
* body a {
  text-decoration: none;
  transition: 0.3s ease-in-out;
}
* body .main-padding {
  padding: var(--g-32, 32px);
}
* body input {
  height: var(--g-32);
}
* body input[type=radio] {
  width: var(--g-16, 16px);
  height: var(--g-16, 16px);
  accent-color: var(--primary-color);
}
* body input[type=checkbox] {
  accent-color: var(--primary-color);
  width: var(--g-16, 16px);
  height: var(--g-16, 16px);
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
* body input[type=checkbox]:disabled {
  cursor: default;
}
* body input,
* body textarea {
  box-sizing: border-box;
  color: var(--primary-color);
  font-family: var(--primary-font, "Roboto", -apple-system, BlinkMacSystemFont, Tahoma, Geneva, Verdana, sans-serif);
  font-size: 14px;
  border: 1px solid var(--input-line-color);
  border-radius: var(--primary-border-radius, 4px);
  padding-left: var(--g-8, 8px);
  padding-right: var(--g-8, 8px);
}
@media (orientation: landscape) and (min-width: 1920px) {
  * body input,
  * body textarea {
    padding-left: 0.4vw;
    padding-right: 0.4vw;
  }
}
@media (orientation: portrait) and (min-width: 1081px) {
  * body input,
  * body textarea {
    padding-left: 0.8vw;
    padding-right: 0.8vw;
  }
}
* body input::placeholder,
* body textarea::placeholder {
  color: var(--tertiary-color);
  text-decoration: none;
  cursor: text;
}
* body input:focus,
* body textarea:focus {
  outline: none;
  border: 1px solid var(--primary-color);
}
* body input:read-only,
* body textarea:read-only {
  background-color: transparent;
  border: none;
}
* body textarea::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  transition: 0.3s ease-in-out;
}
* body textarea::-webkit-scrollbar-track {
  background: #f5f8fa;
  border-radius: 8px;
}
* body textarea::-webkit-scrollbar-thumb {
  background: #175c89;
  border-radius: 8px;
  border: 4px solid rgba(0, 0, 0, 0);
}
* body textarea::-webkit-scrollbar-thumb:hover {
  background: #0095ff;
}
* body .hide {
  display: none !important;
}

body::ng-deep .ag-body-horizontal-scroll-viewport::-webkit-scrollbar, body::ng-deep .ag-body-vertical-scroll-viewport::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  transition: 0.3s ease-in-out;
}
body::ng-deep .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track, body::ng-deep .ag-body-vertical-scroll-viewport::-webkit-scrollbar-track {
  background: #f5f8fa;
  border-radius: 8px;
}
body::ng-deep .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb, body::ng-deep .ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb {
  background: #175c89;
  border-radius: 8px;
  border: 4px solid rgba(0, 0, 0, 0);
}
body::ng-deep .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb:hover, body::ng-deep .ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb:hover {
  background: #0095ff;
}
body app-notifications lib-no-data {
  height: calc(100dvh - 56px - 52px - 32px - 16px);
  display: block;
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-notifications lib-no-data {
    height: calc(100dvh - 2.917vw - 2.708vw - 1.66vw - 0.83vw);
  }
}
@media (orientation: portrait) and (min-width: 1081px) {
  body app-notifications lib-no-data {
    height: calc(100dvh - 5.834vw - 5.416vw - 3.2vw - 1.66vw);
  }
}
body app-forbidden lib-no-data {
  height: calc(100dvh - 56px - 32px - 16px);
  display: block;
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-forbidden lib-no-data {
    height: calc(100dvh - 2.917vw - 1.66vw - 0.83vw);
  }
}
@media (orientation: portrait) and (min-width: 1081px) {
  body app-forbidden lib-no-data {
    height: calc(100dvh - 5.834vw - 3.2vw - 1.66vw);
  }
}
body app-reports-executions-edit {
  height: calc(100dvh - 56px);
  overflow: hidden;
  display: block;
}
body app-reports-executions-filters {
  height: calc(100dvh - 56px - 32px - 84px);
  overflow: auto;
  display: block;
}
body app-reports-executions-filters::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  transition: 0.3s ease-in-out;
}
body app-reports-executions-filters::-webkit-scrollbar-track {
  background: #f5f8fa;
  border-radius: 8px;
}
body app-reports-executions-filters::-webkit-scrollbar-thumb {
  background: #175c89;
  border-radius: 8px;
  border: 4px solid rgba(0, 0, 0, 0);
}
body app-reports-executions-filters::-webkit-scrollbar-thumb:hover {
  background: #0095ff;
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-reports-executions-filters {
    height: calc(100dvh - 3vw - 1.66vw - 4.4vw);
  }
}
@media (orientation: portrait) and (min-width: 1081px) {
  body app-reports-executions-filters {
    height: calc(100dvh - 6.5vw - 3.2vw - 8.3vw);
  }
}
body app-reports-executions-main lib-no-data,
body app-companies-main lib-no-data,
body app-buildings-main lib-no-data,
body app-contacts-main lib-no-data,
body app-opportunities-main lib-no-data,
body app-accounts-main lib-no-data {
  height: 70dvh;
  display: block;
}
@media (min-width: 992px) {
  body app-reports-executions-main lib-no-data,
  body app-companies-main lib-no-data,
  body app-buildings-main lib-no-data,
  body app-contacts-main lib-no-data,
  body app-opportunities-main lib-no-data,
  body app-accounts-main lib-no-data {
    height: 73dvh;
  }
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-reports-executions-main lib-no-data,
  body app-companies-main lib-no-data,
  body app-buildings-main lib-no-data,
  body app-contacts-main lib-no-data,
  body app-opportunities-main lib-no-data,
  body app-accounts-main lib-no-data {
    max-height: 66dvh;
  }
}
@media (min-width: 1366px) {
  body app-reports-executions-main lib-no-data,
  body app-companies-main lib-no-data,
  body app-buildings-main lib-no-data,
  body app-contacts-main lib-no-data,
  body app-opportunities-main lib-no-data,
  body app-accounts-main lib-no-data {
    height: 73dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-reports-executions-main lib-no-data,
  body app-companies-main lib-no-data,
  body app-buildings-main lib-no-data,
  body app-contacts-main lib-no-data,
  body app-opportunities-main lib-no-data,
  body app-accounts-main lib-no-data {
    height: 80dvh;
  }
}
body app-opportunities-single app-notes lib-tab-view .tabContainer {
  max-height: 57dvh;
  overflow: auto;
}
body app-opportunities-single app-notes lib-tab-view .tabContainer::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  transition: 0.3s ease-in-out;
}
body app-opportunities-single app-notes lib-tab-view .tabContainer::-webkit-scrollbar-track {
  background: #f5f8fa;
  border-radius: 8px;
}
body app-opportunities-single app-notes lib-tab-view .tabContainer::-webkit-scrollbar-thumb {
  background: #175c89;
  border-radius: 8px;
  border: 4px solid rgba(0, 0, 0, 0);
}
body app-opportunities-single app-notes lib-tab-view .tabContainer::-webkit-scrollbar-thumb:hover {
  background: #0095ff;
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-opportunities-single app-notes lib-tab-view .tabContainer {
    max-height: 47dvh;
  }
}
@media (min-width: 1366px) {
  body app-opportunities-single app-notes lib-tab-view .tabContainer {
    max-height: 57dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-opportunities-single app-notes lib-tab-view .tabContainer {
    max-height: 66dvh;
  }
}
body app-opportunities-single app-opportunities-single-contacts lib-grid-view .container lib-generic-grid .grid-wrapper,
body app-opportunities-single app-opportunities-single-team lib-grid-view .container lib-generic-grid .grid-wrapper,
body app-opportunities-single app-opportunities-single-activity-log lib-grid-view .container lib-generic-grid .grid-wrapper {
  max-height: 61dvh;
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-opportunities-single app-opportunities-single-contacts lib-grid-view .container lib-generic-grid .grid-wrapper,
  body app-opportunities-single app-opportunities-single-team lib-grid-view .container lib-generic-grid .grid-wrapper,
  body app-opportunities-single app-opportunities-single-activity-log lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 54dvh;
  }
}
@media (min-width: 1366px) {
  body app-opportunities-single app-opportunities-single-contacts lib-grid-view .container lib-generic-grid .grid-wrapper,
  body app-opportunities-single app-opportunities-single-team lib-grid-view .container lib-generic-grid .grid-wrapper,
  body app-opportunities-single app-opportunities-single-activity-log lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 61dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-opportunities-single app-opportunities-single-contacts lib-grid-view .container lib-generic-grid .grid-wrapper,
  body app-opportunities-single app-opportunities-single-team lib-grid-view .container lib-generic-grid .grid-wrapper,
  body app-opportunities-single app-opportunities-single-activity-log lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 71dvh;
  }
}
body app-opportunities-single app-opportunities-single-estimates {
  max-height: 70dvh;
  overflow: auto;
  display: block;
}
body app-opportunities-single app-opportunities-single-estimates::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  transition: 0.3s ease-in-out;
}
body app-opportunities-single app-opportunities-single-estimates::-webkit-scrollbar-track {
  background: #f5f8fa;
  border-radius: 8px;
}
body app-opportunities-single app-opportunities-single-estimates::-webkit-scrollbar-thumb {
  background: #175c89;
  border-radius: 8px;
  border: 4px solid rgba(0, 0, 0, 0);
}
body app-opportunities-single app-opportunities-single-estimates::-webkit-scrollbar-thumb:hover {
  background: #0095ff;
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-opportunities-single app-opportunities-single-estimates {
    max-height: 61dvh;
  }
}
@media (min-width: 1366px) {
  body app-opportunities-single app-opportunities-single-estimates {
    max-height: 70dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-opportunities-single app-opportunities-single-estimates {
    max-height: 75dvh;
  }
}
body app-opportunities-single app-notes lib-no-data,
body app-opportunities-single app-opportunities-single-activity-log lib-no-data {
  height: 50dvh;
  display: block;
}
@media (min-width: 992px) {
  body app-opportunities-single app-notes lib-no-data,
  body app-opportunities-single app-opportunities-single-activity-log lib-no-data {
    height: 60dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-opportunities-single app-notes lib-no-data,
  body app-opportunities-single app-opportunities-single-activity-log lib-no-data {
    height: 70dvh;
  }
}
body app-opportunities-single app-opportunities-single-contacts lib-no-data,
body app-opportunities-single app-opportunities-grid-single lib-no-data,
body app-opportunities-single app-opportunities-single-buildings lib-no-data,
body app-opportunities-single app-opportunities-single-team lib-no-data,
body app-opportunities-single app-opportunities-single-estimates lib-no-data {
  height: 45dvh;
  display: block;
}
@media (min-width: 992px) {
  body app-opportunities-single app-opportunities-single-contacts lib-no-data,
  body app-opportunities-single app-opportunities-grid-single lib-no-data,
  body app-opportunities-single app-opportunities-single-buildings lib-no-data,
  body app-opportunities-single app-opportunities-single-team lib-no-data,
  body app-opportunities-single app-opportunities-single-estimates lib-no-data {
    height: 52dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-opportunities-single app-opportunities-single-contacts lib-no-data,
  body app-opportunities-single app-opportunities-grid-single lib-no-data,
  body app-opportunities-single app-opportunities-single-buildings lib-no-data,
  body app-opportunities-single app-opportunities-single-team lib-no-data,
  body app-opportunities-single app-opportunities-single-estimates lib-no-data {
    height: 68dvh;
  }
}
body app-contacts-single app-notes lib-tab-view .tabContainer {
  max-height: 57dvh;
  overflow: auto;
}
body app-contacts-single app-notes lib-tab-view .tabContainer::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  transition: 0.3s ease-in-out;
}
body app-contacts-single app-notes lib-tab-view .tabContainer::-webkit-scrollbar-track {
  background: #f5f8fa;
  border-radius: 8px;
}
body app-contacts-single app-notes lib-tab-view .tabContainer::-webkit-scrollbar-thumb {
  background: #175c89;
  border-radius: 8px;
  border: 4px solid rgba(0, 0, 0, 0);
}
body app-contacts-single app-notes lib-tab-view .tabContainer::-webkit-scrollbar-thumb:hover {
  background: #0095ff;
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-contacts-single app-notes lib-tab-view .tabContainer {
    max-height: 47dvh;
  }
}
@media (min-width: 1366px) {
  body app-contacts-single app-notes lib-tab-view .tabContainer {
    max-height: 57dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-contacts-single app-notes lib-tab-view .tabContainer {
    max-height: 66dvh;
  }
}
body app-contacts-single app-opportunities-grid-single lib-grid-view .container lib-generic-grid .grid-wrapper {
  max-height: 70dvh;
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-contacts-single app-opportunities-grid-single lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 62dvh;
  }
}
@media (min-width: 1366px) {
  body app-contacts-single app-opportunities-grid-single lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 70dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-contacts-single app-opportunities-grid-single lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 75dvh;
  }
}
body app-contacts-single app-contacts-single-buildings lib-grid-view .container lib-generic-grid .grid-wrapper {
  max-height: 70dvh;
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-contacts-single app-contacts-single-buildings lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 62dvh;
  }
}
@media (min-width: 1366px) {
  body app-contacts-single app-contacts-single-buildings lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 70dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-contacts-single app-contacts-single-buildings lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 75dvh;
  }
}
body app-contacts-single app-notes lib-no-data {
  height: 45dvh;
  display: block;
}
@media (min-width: 992px) {
  body app-contacts-single app-notes lib-no-data {
    height: 53dvh;
  }
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-contacts-single app-notes lib-no-data {
    height: 47dvh;
  }
}
@media (min-width: 1366px) {
  body app-contacts-single app-notes lib-no-data {
    height: 53dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-contacts-single app-notes lib-no-data {
    height: 67dvh;
  }
}
body app-contacts-single app-buildings-single-contacts lib-no-data,
body app-contacts-single app-opportunities-grid-single lib-no-data,
body app-contacts-single app-contacts-single-buildings lib-no-data {
  height: 55dvh;
  display: block;
}
@media (min-width: 992px) {
  body app-contacts-single app-buildings-single-contacts lib-no-data,
  body app-contacts-single app-opportunities-grid-single lib-no-data,
  body app-contacts-single app-contacts-single-buildings lib-no-data {
    height: 66dvh;
  }
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-contacts-single app-buildings-single-contacts lib-no-data,
  body app-contacts-single app-opportunities-grid-single lib-no-data,
  body app-contacts-single app-contacts-single-buildings lib-no-data {
    height: 62dvh;
  }
}
@media (min-width: 1366px) {
  body app-contacts-single app-buildings-single-contacts lib-no-data,
  body app-contacts-single app-opportunities-grid-single lib-no-data,
  body app-contacts-single app-contacts-single-buildings lib-no-data {
    height: 66dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-contacts-single app-buildings-single-contacts lib-no-data,
  body app-contacts-single app-opportunities-grid-single lib-no-data,
  body app-contacts-single app-contacts-single-buildings lib-no-data {
    height: 75dvh;
  }
}
body app-buildings-single app-notes lib-tab-view .tabContainer {
  max-height: 57dvh;
  overflow: auto;
}
body app-buildings-single app-notes lib-tab-view .tabContainer::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  transition: 0.3s ease-in-out;
}
body app-buildings-single app-notes lib-tab-view .tabContainer::-webkit-scrollbar-track {
  background: #f5f8fa;
  border-radius: 8px;
}
body app-buildings-single app-notes lib-tab-view .tabContainer::-webkit-scrollbar-thumb {
  background: #175c89;
  border-radius: 8px;
  border: 4px solid rgba(0, 0, 0, 0);
}
body app-buildings-single app-notes lib-tab-view .tabContainer::-webkit-scrollbar-thumb:hover {
  background: #0095ff;
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-buildings-single app-notes lib-tab-view .tabContainer {
    max-height: 47dvh;
  }
}
@media (min-width: 1366px) {
  body app-buildings-single app-notes lib-tab-view .tabContainer {
    max-height: 57dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-buildings-single app-notes lib-tab-view .tabContainer {
    max-height: 66dvh;
  }
}
body app-buildings-single app-opportunities-grid-single lib-grid-view .container lib-generic-grid .grid-wrapper {
  max-height: 70dvh;
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-buildings-single app-opportunities-grid-single lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 62dvh;
  }
}
@media (min-width: 1366px) {
  body app-buildings-single app-opportunities-grid-single lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 70dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-buildings-single app-opportunities-grid-single lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 75dvh;
  }
}
body app-buildings-single app-buildings-single-contacts lib-grid-view .container lib-generic-grid .grid-wrapper {
  max-height: 65dvh;
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-buildings-single app-buildings-single-contacts lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 55dvh;
  }
}
@media (min-width: 1366px) {
  body app-buildings-single app-buildings-single-contacts lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 65dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-buildings-single app-buildings-single-contacts lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 71vh;
  }
}
body app-buildings-single app-notes lib-no-data {
  height: 45dvh;
  display: block;
}
@media (min-width: 992px) {
  body app-buildings-single app-notes lib-no-data {
    height: 53dvh;
  }
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-buildings-single app-notes lib-no-data {
    height: 47dvh;
  }
}
@media (min-width: 1366px) {
  body app-buildings-single app-notes lib-no-data {
    height: 53dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-buildings-single app-notes lib-no-data {
    height: 67dvh;
  }
}
body app-buildings-single app-buildings-single-contacts lib-no-data,
body app-buildings-single app-buildings-single-companies lib-no-data,
body app-buildings-single app-opportunities-grid-single lib-no-data {
  height: 55dvh;
  display: block;
}
@media (min-width: 992px) {
  body app-buildings-single app-buildings-single-contacts lib-no-data,
  body app-buildings-single app-buildings-single-companies lib-no-data,
  body app-buildings-single app-opportunities-grid-single lib-no-data {
    height: 66dvh;
  }
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-buildings-single app-buildings-single-contacts lib-no-data,
  body app-buildings-single app-buildings-single-companies lib-no-data,
  body app-buildings-single app-opportunities-grid-single lib-no-data {
    height: 55dvh;
  }
}
@media (min-width: 1366px) {
  body app-buildings-single app-buildings-single-contacts lib-no-data,
  body app-buildings-single app-buildings-single-companies lib-no-data,
  body app-buildings-single app-opportunities-grid-single lib-no-data {
    height: 66dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-buildings-single app-buildings-single-contacts lib-no-data,
  body app-buildings-single app-buildings-single-companies lib-no-data,
  body app-buildings-single app-opportunities-grid-single lib-no-data {
    height: 75dvh;
  }
}
body app-accounts-single app-notes lib-tab-view .tabContainer {
  height: 57dvh;
  overflow: auto;
}
body app-accounts-single app-notes lib-tab-view .tabContainer::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  transition: 0.3s ease-in-out;
}
body app-accounts-single app-notes lib-tab-view .tabContainer::-webkit-scrollbar-track {
  background: #f5f8fa;
  border-radius: 8px;
}
body app-accounts-single app-notes lib-tab-view .tabContainer::-webkit-scrollbar-thumb {
  background: #175c89;
  border-radius: 8px;
  border: 4px solid rgba(0, 0, 0, 0);
}
body app-accounts-single app-notes lib-tab-view .tabContainer::-webkit-scrollbar-thumb:hover {
  background: #0095ff;
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-accounts-single app-notes lib-tab-view .tabContainer {
    height: 47dvh;
  }
}
@media (min-width: 1366px) {
  body app-accounts-single app-notes lib-tab-view .tabContainer {
    height: 57dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-accounts-single app-notes lib-tab-view .tabContainer {
    height: 66dvh;
  }
}
body app-accounts-single app-opportunities-grid-single lib-grid-view .container lib-generic-grid .grid-wrapper {
  max-height: 70dvh;
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-accounts-single app-opportunities-grid-single lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 62dvh;
  }
}
@media (min-width: 1366px) {
  body app-accounts-single app-opportunities-grid-single lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 70dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-accounts-single app-opportunities-grid-single lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 75dvh;
  }
}
body app-accounts-single app-accounts-single-buildings lib-grid-view .container lib-generic-grid .grid-wrapper,
body app-accounts-single app-accounts-single-contacts lib-grid-view .container lib-generic-grid .grid-wrapper,
body app-accounts-single app-accounts-single-companies lib-grid-view .container lib-generic-grid .grid-wrapper {
  max-height: 65dvh;
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-accounts-single app-accounts-single-buildings lib-grid-view .container lib-generic-grid .grid-wrapper,
  body app-accounts-single app-accounts-single-contacts lib-grid-view .container lib-generic-grid .grid-wrapper,
  body app-accounts-single app-accounts-single-companies lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 55dvh;
  }
}
@media (min-width: 1366px) {
  body app-accounts-single app-accounts-single-buildings lib-grid-view .container lib-generic-grid .grid-wrapper,
  body app-accounts-single app-accounts-single-contacts lib-grid-view .container lib-generic-grid .grid-wrapper,
  body app-accounts-single app-accounts-single-companies lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 65dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-accounts-single app-accounts-single-buildings lib-grid-view .container lib-generic-grid .grid-wrapper,
  body app-accounts-single app-accounts-single-contacts lib-grid-view .container lib-generic-grid .grid-wrapper,
  body app-accounts-single app-accounts-single-companies lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 71dvh;
  }
}
body app-accounts-single app-notes lib-no-data {
  height: 45dvh;
  display: block;
}
@media (min-width: 992px) {
  body app-accounts-single app-notes lib-no-data {
    height: 53dvh;
  }
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-accounts-single app-notes lib-no-data {
    height: 47dvh;
  }
}
@media (min-width: 1366px) {
  body app-accounts-single app-notes lib-no-data {
    height: 53dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-accounts-single app-notes lib-no-data {
    height: 67dvh;
  }
}
body app-accounts-single app-accounts-single-buildings lib-no-data,
body app-accounts-single app-opportunities-grid-single lib-no-data,
body app-accounts-single app-accounts-single-contacts lib-no-data,
body app-accounts-single app-accounts-single-companies lib-no-data {
  height: 55dvh;
  display: block;
}
@media (min-width: 992px) {
  body app-accounts-single app-accounts-single-buildings lib-no-data,
  body app-accounts-single app-opportunities-grid-single lib-no-data,
  body app-accounts-single app-accounts-single-contacts lib-no-data,
  body app-accounts-single app-accounts-single-companies lib-no-data {
    height: 66dvh;
  }
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-accounts-single app-accounts-single-buildings lib-no-data,
  body app-accounts-single app-opportunities-grid-single lib-no-data,
  body app-accounts-single app-accounts-single-contacts lib-no-data,
  body app-accounts-single app-accounts-single-companies lib-no-data {
    max-height: 55dvh;
  }
}
@media (min-width: 1366px) {
  body app-accounts-single app-accounts-single-buildings lib-no-data,
  body app-accounts-single app-opportunities-grid-single lib-no-data,
  body app-accounts-single app-accounts-single-contacts lib-no-data,
  body app-accounts-single app-accounts-single-companies lib-no-data {
    max-height: 66dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-accounts-single app-accounts-single-buildings lib-no-data,
  body app-accounts-single app-opportunities-grid-single lib-no-data,
  body app-accounts-single app-accounts-single-contacts lib-no-data,
  body app-accounts-single app-accounts-single-companies lib-no-data {
    height: 75dvh;
  }
}
body app-companies-single app-notes lib-tab-view .tabContainer {
  height: 57dvh;
  overflow: auto;
}
body app-companies-single app-notes lib-tab-view .tabContainer::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  transition: 0.3s ease-in-out;
}
body app-companies-single app-notes lib-tab-view .tabContainer::-webkit-scrollbar-track {
  background: #f5f8fa;
  border-radius: 8px;
}
body app-companies-single app-notes lib-tab-view .tabContainer::-webkit-scrollbar-thumb {
  background: #175c89;
  border-radius: 8px;
  border: 4px solid rgba(0, 0, 0, 0);
}
body app-companies-single app-notes lib-tab-view .tabContainer::-webkit-scrollbar-thumb:hover {
  background: #0095ff;
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-companies-single app-notes lib-tab-view .tabContainer {
    height: 47dvh;
  }
}
@media (min-width: 1366px) {
  body app-companies-single app-notes lib-tab-view .tabContainer {
    height: 57dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-companies-single app-notes lib-tab-view .tabContainer {
    height: 66dvh;
  }
}
body app-companies-single app-opportunities-grid-single lib-grid-view .container lib-generic-grid .grid-wrapper {
  max-height: 70dvh;
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-companies-single app-opportunities-grid-single lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 62dvh;
  }
}
@media (min-width: 1366px) {
  body app-companies-single app-opportunities-grid-single lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 70dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-companies-single app-opportunities-grid-single lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 75dvh;
  }
}
body app-companies-single app-companies-single-buildings lib-grid-view .container lib-generic-grid .grid-wrapper,
body app-companies-single app-companies-single-contacts lib-grid-view .container lib-generic-grid .grid-wrapper {
  max-height: 65dvh;
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-companies-single app-companies-single-buildings lib-grid-view .container lib-generic-grid .grid-wrapper,
  body app-companies-single app-companies-single-contacts lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 55dvh;
  }
}
@media (min-width: 1366px) {
  body app-companies-single app-companies-single-buildings lib-grid-view .container lib-generic-grid .grid-wrapper,
  body app-companies-single app-companies-single-contacts lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 65dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-companies-single app-companies-single-buildings lib-grid-view .container lib-generic-grid .grid-wrapper,
  body app-companies-single app-companies-single-contacts lib-grid-view .container lib-generic-grid .grid-wrapper {
    max-height: 71dvh;
  }
}
body app-companies-single app-notes lib-no-data {
  height: 45dvh;
  display: block;
}
@media (min-width: 992px) {
  body app-companies-single app-notes lib-no-data {
    height: 53dvh;
  }
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-companies-single app-notes lib-no-data {
    height: 47dvh;
  }
}
@media (min-width: 1366px) {
  body app-companies-single app-notes lib-no-data {
    height: 53dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-companies-single app-notes lib-no-data {
    height: 67dvh;
  }
}
body app-companies-single app-companies-single-buildings lib-no-data,
body app-companies-single app-opportunities-grid-single lib-no-data,
body app-companies-single app-companies-single-contacts lib-no-data {
  height: 55dvh;
  display: block;
}
@media (min-width: 992px) {
  body app-companies-single app-companies-single-buildings lib-no-data,
  body app-companies-single app-opportunities-grid-single lib-no-data,
  body app-companies-single app-companies-single-contacts lib-no-data {
    height: 66dvh;
  }
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-companies-single app-companies-single-buildings lib-no-data,
  body app-companies-single app-opportunities-grid-single lib-no-data,
  body app-companies-single app-companies-single-contacts lib-no-data {
    max-height: 55dvh;
  }
}
@media (min-width: 1366px) {
  body app-companies-single app-companies-single-buildings lib-no-data,
  body app-companies-single app-opportunities-grid-single lib-no-data,
  body app-companies-single app-companies-single-contacts lib-no-data {
    max-height: 66dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-companies-single app-companies-single-buildings lib-no-data,
  body app-companies-single app-opportunities-grid-single lib-no-data,
  body app-companies-single app-companies-single-contacts lib-no-data {
    height: 75dvh;
  }
}
body app-configuration app-customization app-customization-custom-fields lib-no-data {
  height: 55dvh;
  display: block;
}
@media (min-width: 992px) {
  body app-configuration app-customization app-customization-custom-fields lib-no-data {
    height: 63dvh;
  }
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-configuration app-customization app-customization-custom-fields lib-no-data {
    height: 55dvh;
  }
}
@media (min-width: 1366px) {
  body app-configuration app-customization app-customization-custom-fields lib-no-data {
    height: 63dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-configuration app-customization app-customization-custom-fields lib-no-data {
    height: 75dvh;
  }
}
body app-configuration app-customization app-customization-custom-fields lib-no-data,
body app-configuration app-customization app-customization-custom-info lib-no-data {
  height: 55dvh;
  display: block;
}
@media (min-width: 992px) {
  body app-configuration app-customization app-customization-custom-fields lib-no-data,
  body app-configuration app-customization app-customization-custom-info lib-no-data {
    height: 63dvh;
  }
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-configuration app-customization app-customization-custom-fields lib-no-data,
  body app-configuration app-customization app-customization-custom-info lib-no-data {
    height: 55dvh;
  }
}
@media (min-width: 1366px) {
  body app-configuration app-customization app-customization-custom-fields lib-no-data,
  body app-configuration app-customization app-customization-custom-info lib-no-data {
    height: 63dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-configuration app-customization app-customization-custom-fields lib-no-data,
  body app-configuration app-customization app-customization-custom-info lib-no-data {
    height: 75dvh;
  }
}
body app-reports-main app-reports-list lib-no-data {
  height: 70dvh;
  display: block;
}
@media (min-width: 992px) {
  body app-reports-main app-reports-list lib-no-data {
    height: 70dvh;
  }
}
@media (min-width: 1200px) and (max-width: 1366px) {
  body app-reports-main app-reports-list lib-no-data {
    height: 63dvh;
  }
}
@media (min-width: 1366px) {
  body app-reports-main app-reports-list lib-no-data {
    height: 70dvh;
  }
}
@media (orientation: landscape) and (min-width: 1920px) {
  body app-reports-main app-reports-list lib-no-data {
    height: 80dvh;
  }
}

/*app-root {
    > div {
        overflow: hidden;
    }
}*/
.cdk-overlay-custom-backdrop {
  background-color: transparent;
}
@use "variables/colors";
@use "variables/custom-properties";
@use "variables/media-queries";
@use "utilities";

button {
    font-family: var(--primary-font, "Roboto");
    font-size: 14px;
    font-weight: utilities.font-weight(regular);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: var(--g-8, 8px);
    padding-top: var(--g-8, 8px);
    padding-bottom: var(--g-8, 8px);
    padding-left: var(--g-16, 16px);
    padding-right: var(--g-16, 16px);
    height: var(--g-32, 32px);
    width: 100%;
    line-height: 100%;
    border-radius: var(--primary-border-radius, 4px);
    transition: all 0.3s ease-in-out;
    white-space: nowrap;

    @media (orientation: landscape) {
        @media #{media-queries.$break-xxxl} {
            font-size: 0.72vw;
            gap: 0.4vw;
            padding-top: 0.4vw;
            padding-bottom: 0.4vw;
            padding-left: 0.83vw;
            padding-right: 0.83vw;
            height: 1.667vw;
            border-radius: 0.2vw;
        }
    }

    @media (orientation: portrait) {
        @media #{media-queries.$break-lg} {
            font-size: 1.44vw;
            gap: 0.8vw;
            padding-top: 0.8vw;
            padding-bottom: 0.8vw;
            padding-left: 1.667vw;
            padding-right: 1.667vw;
            height: 3.33vw;
            border-radius: 0.4vw;
        }
    }

    &.resetStyles {
        font: inherit;
        color: inherit;
        margin: 0;
        padding: 0;
        background: none;
        border: none;
        width: auto;
        line-height: normal;

        @media (orientation: landscape) {
            @media #{media-queries.$break-xxxl} {
                margin: 0;
                padding: 0;
            }
        }

        @media (orientation: portrait) {
            @media #{media-queries.$break-lg} {
                margin: 0;
                padding: 0;
            }
        }
    }

    &.primary-btn {
        font-size: 14px;
        background-color: var(--primary-color, #175c89);
        color: var(--white, #ffffff);
        border: 1px solid var(--primary-color, #175c89);

        &:hover {
            background-color: var(--hover-color, #457da6);
            color: var(--white, #ffffff);
            outline: none;
            border-color: var(--hover-color, #457da6);
        }

        &:active {
            background-color: var(--active-color, #0e4062);
            color: var(--white, #ffffff);
            outline: none;
            border-color: var(--active-color, #0e4062);
        }

        &:focus,
        &:focus-within {
            background-color: var(--hover-color, #457da6);
            color: var(--white, #ffffff);
            outline: none;
            border-color: var(--hover-color, #457da6);
            &:hover {
                background-color: var(--hover-color, #457da6);
                color: var(--white, #ffffff);
                outline: none;
                border-color: var(--hover-color, #457da6);
            }
        }

        &:focus-visible {
            outline: 1.5px solid var(--black, #000000);
            background-color: var(--primary-color, #175c89);
            color: var(--white, #ffffff);
            border-color: var(--white, #ffffff);
            &:hover {
                background-color: var(--hover-color, #457da6);
                color: var(--white, #ffffff);
                outline: 1.5px solid var(--black, #000000);
                border-color: var(--white, #ffffff);
            }
        }

        &:disabled {
            background-color: #d1d6d9;
            color: #ffffff;
            border-color: #d1d6d9;

            svg {
                path {
                    stroke: #ffffff;
                }
            }
        }

        @media (orientation: landscape) {
            @media #{media-queries.$break-xxxl} {
                font-size: 0.72vw;
            }
        }

        @media (orientation: portrait) {
            @media #{media-queries.$break-lg} {
                font-size: 1.44vw;
            }
        }
    }

    &.secondary-btn {
        font-size: 14px;
        background-color: transparent;
        color: var(--primary-color, #175c89);
        border: 1px solid var(--primary-color, #175c89);

        &:hover {
            background-color: var(--lighter-gray, #f5f8fa);
            border-color: var(--primary-color, #175c89);
        }

        &:active {
            background-color: var(--lighter-gray, #e3ecf2);
            border-color: var(--primary-color, #175c89);
        }

        &:focus,
        &:focus-within {
            outline: none;
            background-color: var(--lighter-gray, #e3ecf2);
            border-color: var(--primary-color, #175c89);
        }

        &:focus-visible {
            outline: 1.5px solid var(--black, #000000);
            border-color: var(--primary-color, #175c89);
        }

        &:disabled {
            background-color: #ffffff;
            color: #98b7d2;
            border-color: #98b7d2;

            svg {
                path {
                    stroke: #98b7d2;
                }
            }
        }

        @media (orientation: landscape) {
            @media #{media-queries.$break-xxxl} {
                font-size: 0.72vw;
            }
        }

        @media (orientation: portrait) {
            @media #{media-queries.$break-lg} {
                font-size: 1.44vw;
            }
        }
    }

    &.tertiary-btn {
        font-size: 14px;
        background-color: transparent;
        border: none;
        color: var(--primary-color, #175c89);
        outline: none;

        .icon {
            svg {
                @media (orientation: landscape) {
                    @media #{media-queries.$break-xxxl} {
                        width: 0.625vw;
                        height: 0.625vw;
                    }
                }

                @media (orientation: portrait) {
                    @media #{media-queries.$break-lg} {
                        width: 1.2vw;
                        height: 1.2vw;
                    }
                }
            }
        }

        &:active {
            color: var(--primary-color, #0e4062);
            outline: none;
            border: none;

            svg {
                path {
                    stroke: var(--primary-color, #0e4062);
                }
            }
        }

        &:hover {
            color: var(--secondary-color, #0095ff);
            outline: none;
            border: none;

            svg {
                path {
                    stroke: var(--secondary-color, #0095ff);
                }
            }
        }

        &:focus,
        &:focus-within {
            color: var(--primary-color, #0e4062);
            outline: none;
            border: none;

            svg {
                path {
                    stroke: var(--primary-color, #0e4062);
                }
            }
        }

        &:focus-visible {
            color: var(--primary-color, #175c89);
            outline: 1.5px solid var(--black, #000000);
            border-color: none;
        }

        &:disabled {
            color: var(--quaternary-color, #98b7d2);
            outline: none;
            border: none;

            svg {
                path {
                    stroke: var(--quaternary-color, #98b7d2);
                }
            }
        }

        @media (orientation: landscape) {
            @media #{media-queries.$break-xxxl} {
                font-size: 0.72vw;
            }
        }

        @media (orientation: portrait) {
            @media #{media-queries.$break-lg} {
                font-size: 1.44vw;
            }
        }
    }

    &.small {
        font-size: 12px;
        height: var(--g-24, 24px);

        @media (orientation: landscape) {
            @media #{media-queries.$break-xxxl} {
                font-size: 0.62vw;
                height: 1.25vw;
            }
        }

        @media (orientation: portrait) {
            @media #{media-queries.$break-lg} {
                font-size: 1.24vw;
                height: 2.5vw;
            }
        }
    }

    &.borderless {
        border: none;
    }
}

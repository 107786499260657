@use 'sass:math';

// Custom scrollbar
@mixin custom_scrollbar(
	$trackColor: #f5f8fa,
	$thumbColor: #175c89,
	$widthSize: 6px,
	$heightSize: 6px,
	$thumbColorHover: #0095ff,
	$borderRadius: 8px,
	$border: 4px solid rgba(0 0 0 / 0),
	$boxShadow: inset -1px -1px 0 rgba(0 0 0 / 0.05) inset 1px 1px 0 rgba(0 0 0 / 0.05)
) {
	&::-webkit-scrollbar {
		width: $widthSize;
		height: $heightSize;
		transition: 0.3s ease-in-out;

		&-track {
			background: $trackColor;
			border-radius: $borderRadius;
		}
		&-thumb {
			background: $thumbColor;
			border-radius: $borderRadius;
			border: $border;
			&:hover {
				background: $thumbColorHover;
			}
		}
	}
}

// Responsive font size
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
	$responsive-unitless: math.div($responsive, $responsive - $responsive + 1);
	$dimension: if(unit($responsive) == 'vh', 'height', 'width');
	$min-breakpoint: math.div($min, $responsive-unitless) * 100;

	@media (max-#{$dimension}: #{$min-breakpoint}) {
		font-size: $min;
	}

	@if $max {
		$max-breakpoint: $max / $responsive-unitless * 100;

		@media (min-#{$dimension}: #{$max-breakpoint}) {
			font-size: $max;
		}
	}

	@if $fallback {
		font-size: $fallback;
	}

	font-size: $responsive;
}

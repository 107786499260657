// Roboto

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 100;
    src: url("../../assets/fonts/Roboto-Thin.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    src: url("../../assets/fonts/Roboto-Light.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src: url("../../assets/fonts/Roboto-Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    src: url("../../assets/fonts/Roboto-Medium.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    src: url("../../assets/fonts/Roboto-Bold.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    src: url("../../assets/fonts/Roboto-Black.ttf") format("truetype");
    font-display: swap;
}
